class FloatingAlert {

    constructor(containerId) {
        const container = document.getElementById(containerId)
        const setDismissed = () => setCookie(containerId, 1)
        const dismiss = () => {
            setDismissed()
            container.classList.add('dismissed')
        }

        if (!readCookie(containerId)) {
            container.classList.remove('dismissed')
            container.querySelectorAll('.close[data-dismiss="alert"]').forEach(close => close.addEventListener('click', dismiss))
            container.querySelectorAll('a').forEach(element => element.addEventListener('click', setDismissed))
        }
    }
}